<template>
  <div>
    <table-filter :filters="filters" :statusFilters="statusFilters" @changeFilter="changeFilter" @filterValue="filterValue = $event"/>
    <!-- TableLoader -->
    <table v-if="tableLoader" class="table-loader w-full mt-3 text-center">
      <tr>
        <th v-for="(h, index) in 10" :key="index" class="py-3 lg:px-7 px-3">  
          <div class="line w-full rounded-lg h-4 my-3"></div>
        </th>
      </tr>
      <tr v-for="(r, index) in 5" :key="index">
        <td v-for="(b, index) in 10" :key="index" class="py-3 lg:px-5 px-1">
          <div class="line w-full rounded-lg h-4 my-2"></div>
        </td>
      </tr>
    </table>
    <div v-if="!isAndroid && !tableLoader">
      <vs-table
        class="supplies-table"
        pagination
        orders
        :max-items="maximumItems"
        :data="supplies"
        >
        <template slot="thead">
          <vs-th>{{$t('ID')}}</vs-th>
          <vs-th>{{$t('Created On')}}</vs-th>
          <vs-th>{{$t('Total Quantity')}}</vs-th>
          <vs-th>{{$t('Price')}} ({{$t('EGP')}})</vs-th>
          <vs-th>{{$t('Status')}}</vs-th>
          <vs-th>{{$t('Payment Status')}}</vs-th>
          <vs-th>{{$t('Actions')}}</vs-th>
        </template>

        <template slot-scope="{data}">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :style="`--statusColor: ${getStyle(data[indextr])}`">
            <vs-td :data="data[indextr].id">
              {{ data[indextr].id }}
            </vs-td>

            <vs-td :data="data[indextr].created">
              <p class="text-darkblue"> {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }} </p>
            </vs-td>

            <vs-td :data="data[indextr].created">
              <div class="px-6">
                {{countItem(tr.supply_request_items)}} {{$t('Items')}}
              </div>
            </vs-td>

            <vs-td :data="data[indextr].price">
              {{ data[indextr].supplies_price.total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}
            </vs-td>

            <vs-td :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`">
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize">{{ $t(getStatusLabel(data[indextr])) }}</span>
              </div>
            </vs-td>

            <vs-td :data="data[indextr].status">
              <template v-if="data[indextr].payment_requests.length > 0">
                <div :style="`color: ${getPaymentStyle(data[indextr])}`" class="m-auto flex items-center justify-center font-semibold">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="capitalize">{{ $t(getPaymentStatusLabel(data[indextr].payment_requests[0])) }}</span>
                </div>
              </template>
              <div v-if="data[indextr].status === 'pending_payment'">
                <span v-if="data[indextr].status === 'pending_payment' || (data[indextr].payment_requests.length > 0 && data[indextr].payment_requests[0].status === 'pending')" @click="openPaymentModal(data[indextr])" class="font-medium underline text-primary">{{ $t('Pay') }}</span>
              </div>
            </vs-td>

            <vs-td :data="tr.id">
              <vs-dropdown
                vs-trigger-click
                class="dd-actions cursor-pointer"
              >
                <vs-button
                  type="border"
                  size="small"
                  icon-pack="feather"
                  icon="icon-more-horizontal"
                  class="mr-2"
                >
                </vs-button>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    :disabled="!(data[indextr].status === 'created' || data[indextr].status === 'pending_payment' || (data[indextr].status === 'requested' && data[indextr].payment_requests.filter(item => item.status === 'paid').length > 0))"
                    @click="cancelSupply(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="XCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Cancel Supplies Request')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-td>
            <div slot="expand">
              <div class="flex gap-2 pt-3 pb-7">
                <div class="w-full" v-if="tr.supply_request_items.filter(item => item.package_size && item.package_size.type === 'flyer' && item.count > 0).length > 0">
                  <div class="pb-3">
                    <p class="text-sm font-bold text-black">{{$t('Flyers')}}</p>
                  </div>
                  <div class="text-sm" >
                    <p class="flex w-1/3" v-for="item in tr.supply_request_items" :key="item.index">
                      <span class="w-1/2 text-darkblue" v-if="item.count > 0 && item.package_size && item.package_size.type === 'flyer'">{{item.package_size.name.split('(')[0]}}</span>
                      <span class="w-1/2 text-darkblue" v-if="item.count > 0 && item.package_size && item.package_size.type === 'flyer'"><span class="font-bold pr-3 text-black">{{$t('QTY')}}</span> <span class="text-darkgray">{{ item.count }}</span> </span>
                    </p>
                  </div>
                </div>
                <div class="w-full" v-if="tr.supply_request_items.filter(item => item.package_size && item.package_size.type === 'box' && item.count > 0).length > 0">
                  <div class="pb-3">
                    <p class="text-sm font-bold text-black">{{$t('Boxes')}}</p>
                  </div>
                  <div class="text-xs">
                    <p class="flex w-1/3" v-for="item in tr.supply_request_items" :key="item.index">
                      <span class="w-1/2 text-darkblue" v-if="item.count > 0 && item.package_size && item.package_size.type === 'box'">{{item.package_size.name.split('(')[0]}}</span>
                      <span class="w-1/2 text-darkblue" v-if="item.count > 0 && item.package_size && item.package_size.type === 'box'"><span class="font-bold pr-3 text-black">{{$t('QTY')}}</span> <span class="text-darkgray">{{ item.count }}</span> </span>
                    </p>
                  </div>
                </div>
                <div class="w-full" v-if="tr.supply_request_items.filter(item => item.package_size && item.package_size.type === 'other' && item.count > 0).length > 0">
                  <div class="pb-3">
                    <p class="text-sm font-bold text-black">{{$t('Other Items')}}</p>
                  </div>
                  <div class="text-xs">
                    <p class="flex w-1/3" v-for="item in tr.supply_request_items" :key="item.index">
                      <span class="w-1/2 text-darkblue" v-if="item.count > 0 && item.package_size && item.package_size.type === 'other'">{{item.package_size.name.split('(')[0]}}</span>
                      <span class="w-1/2 text-darkblue" v-if="item.count > 0 && item.package_size && item.package_size.type === 'other'"><span class="font-bold pr-3 text-black">{{$t('QTY')}}</span> <span class="text-darkgray">{{ item.count }}</span> </span>
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="data[indextr].payment_requests.length > 0 && data[indextr].payment_requests[0].transaction_set !== null" class="border-line flex gap-2 pt-3 pb-7">
                <div class="w-full">
                  <div class="pb-3">
                    <p class="text-sm font-bold text-black">{{$t('Price Details')}}</p>
                  </div>
                  <div class="text-sm">
                    <p class="flex w-1/3">
                      <span class="w-1/4 text-darkblue">{{$t('Amount')}}</span>
                      <span class="w-1/4 text-darkblue text-right">
                      <span class="font-bold text-black" v-if="data[indextr].supplies_price.price_breakdown_item.filter(item => item.description === 'Credit card processing fees').length > 0">
                        {{ Number(data[indextr].supplies_price.subtotal - data[indextr].supplies_price.price_breakdown_item.filter(item => item.description === 'Credit card processing fees')[0].value).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}
                      </span>
                      <span class="font-bold text-black" v-else>
                        {{ data[indextr].supplies_price.subtotal.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</span>
                      </span>
                    </p>
                    <p class="flex w-1/3" v-if="data[indextr].payment_requests[0].status === 'paid' && data[indextr].supplies_price.price_breakdown_item.filter(item => item.description === 'Credit card processing fees').length === 1">
                      <span class="w-1/4 text-darkblue">{{$t('Fees')}}</span>
                      <span class="w-1/4 text-darkblue text-right"><span class="font-bold text-black">{{ data[indextr].supplies_price.price_breakdown_item.filter(item => item.description === 'Credit card processing fees')[0].value.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span> </span>
                    </p>
                    <p class="flex w-1/3">
                      <span class="w-1/4 text-darkblue">{{$t('Vat (14%)')}}</span>
                      <span class="w-1/4 text-darkblue text-right"><span class="font-bold text-black">{{ data[indextr].supplies_price.vat.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span> </span>
                    </p>
                     <p class="flex w-1/3">
                      <span class="w-1/4 text-darkblue">{{$t('Total')}}</span>
                      <span class="w-1/4 text-darkblue text-right"><span class="font-bold text-black">{{ data[indextr].supplies_price.total.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span> </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </vs-tr>
        </template>
      </vs-table>
      <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    </div>
    <div v-if="isAndroid && !tableLoader">
      <vs-collapse class="supply-order" >
        <vs-collapse-item class="mt-4 bg-color rounded-4xl" v-for="(item) in supplies" :key="item.index">
          <div slot="header" class="p-4">
            <div class="b-color flex items-center justify-between">
              <div class="ml-6">
                <p class="font-medium text-base">{{countItem(item.supply_request_items)}} {{$t('Items')}}</p>
                <p class="font-normal text-sm mt-1">{{item.supplies_price.total}}</p>
              </div>
              <div class="text-center">
                <div v-on:click.stop>
                  <vs-dropdown
                    vs-trigger-click
                    class="dd-actions cursor-pointer"
                  >
                    <vs-button
                      type="border"
                      size="small"
                      icon-pack="feather"
                      icon="icon-more-horizontal"
                      class="mr-2"
                    >
                    </vs-button>
                    <vs-dropdown-menu style="width: 190px">
                      <vs-dropdown-item>
                        <span class="flex items-center">
                          <feather-icon
                            icon="EyeIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>{{$t('View')}}</span>
                        </span>
                      </vs-dropdown-item>
                      <vs-dropdown-item>
                        <span class="flex items-center">
                          <feather-icon
                            icon="BoxIcon"
                            svgClasses="h-4 w-4"
                            class="mr-2"
                          />
                          <span>{{$t('Confirm to Ship')}}</span>
                        </span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
                <div class="m-auto mr-4 flex items-center justify-center font-semibold" :style="`color: ${getStyle(item)}`">
                  <div><span class="status-icon mr-1"></span></div>
                  <span class="text-xs mt-2 capitalize">{{ $t(getStatusLabel(item)) }}</span>
                </div>
                <template v-if="item.payment_requests.length > 0">
                  <div :style="`color: ${getPaymentStyle(item)}`" class="m-auto flex items-center mr-4 justify-center font-semibold">
                    <div><span class="status-icon mr-1"></span></div>
                    <span class="capitalize text-xs">{{ $t(getPaymentStatusLabel(item.payment_requests[0])) }}</span>
                  </div>
                </template>
                <div v-if="item.status === 'pending_payment'">
                  <span v-if="item.status === 'pending_payment' || (item.payment_requests.length > 0 && item.payment_requests[0].status === 'pending')" @click="openPaymentModal(item)" class="font-medium underline text-primary mr-4 text-sm">{{ $t('Pay') }}</span>
                </div>
            </div>
          </div>
          </div>

          <div class="px-12 py-4">
            <div class="pb-3">
              <p class="text-sm font-medium">{{$t('Flyers')}}</p>
            </div>
            <div class=" text-xs">
              <div class="flex justify-between" v-for="element in item.supply_request_items" :key="element.index">
                <span v-if="element.count > 0 && element.package_size.type === 'flyer'">{{element.package_size.name.split('(')[0]}}</span>
                <span v-if="element.count > 0 && element.package_size.type === 'flyer'"><span class="font-bold pr-3">{{$t('QTY')}}</span> {{ element.count }}</span>
              </div> 
            </div>
          </div>

          <div class="px-12 py-4">
            <div class="pb-3">
              <p class="text-sm font-medium">{{$t('Boxes')}}</p>
            </div>
            <div class=" text-xs">
              <div class="flex justify-between" v-for="element in item.supply_request_items" :key="element.index">
                <span v-if="element.count > 0 && element.package_size.type === 'box'">{{element.package_size.name.split('(')[0]}}</span>
                <span v-if="element.count > 0 && element.package_size.type === 'box'"><span class="font-bold pr-3">{{$t('QTY')}}</span> {{ element.count }}</span>
              </div> 
            </div>
          </div>

          <div class="px-12 py-4">
            <div class="pb-3">
              <p class="text-sm font-medium">{{$t('Other Items')}}</p>
            </div>
            <div class=" text-xs">
              <div class="flex justify-between" v-for="element in item.supply_request_items" :key="element.index">
                <span v-if="element.count > 0 && element.package_size.type === 'other'">{{element.package_size.name.split('(')[0]}}</span>
                <span v-if="element.count > 0 && element.package_size.type === 'other'"><span class="font-bold pr-3">{{$t('QTY')}}</span> {{ element.count }}</span>
              </div> 
            </div>
          </div>
        </vs-collapse-item>
      </vs-collapse>
      <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
    </div>
    <payment-modal :paymentModalFlag="paymentModalFlag" @paymentModalFlag="paymentModalFlag = $event" @loadSupplies="loadSupplies" :payableID="payableID"></payment-modal>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import {sendRequest} from '../../http/axios/requestHelper'
import i18nData from '../../i18n/i18nData'
import paymentModal from './components/PaymentModal.vue'
import TableFilter from './components/TableFilter.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'

export default {
  props: ['selectLocationsModal'],
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      isAndroid: false,
      supplies: [],
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: ''
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'requested',
          value: 'requested'
        },
        {
          name: 'pending payment',
          value: 'pending_payment'
        },
        {
          name: 'attempted',
          value: 'delivery_attempted'
        },
        {
          name: 'out for delivery',
          value: 'out_for_delivery'
        },
        {
          name: 'delivered',
          value: 'delivered'
        },
        {
          name: 'cancelled',
          value: 'cancelled'
        },
        {
          name: 'expired',
          value: 'expired'
        }
      ],
      searchVal: '',
      packagesSize: [],
      packagesDic: {},
      filters: [],
      supplyData: {},
      offset: 0,
      agentURl: 'api/v1/supplies/',
      selected: [],
      supplyModal: false,
      type: '',
      supply: {},
      paymentModalFlag: false,
      payableID: ''
    }
  },
  components: {
    vSelect,
    paymentModal,
    TableFilter,
    ShipbluPagination,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          filter: this.filters,
          type: 'all',
          tab: 'supplies-orders',
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadSupplies()
    }
  },
  methods: {
    openPaymentModal (data) {
      this.paymentModalFlag = true
      this.payableID = data.payment_requests.length > 0 && data.payment_requests ? data.payment_requests[0].id : ''
    },
    isAndroidFunction () {
      if (/(android)/i.test(navigator.userAgent)) {
        this.isAndroid = true
      } else {
        this.isAndroid = false
      }
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.currentPage = 1
      this.$router.push({
        query: {
          type: 'all',
          tab: 'supplies-orders',
          page: this.currentPage,
          filter: this.filters
        }
      }).catch(() => {})
      this.loadSupplies()
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadSupplies()
    },
    loadPackagesSize () {
      sendRequest(true, false, this, 'api/v1/package-sizes/', 'get', null, true,
        (response) => {
          const data = response.data
          const time = new Date()
          const PackageSizesDataTime = {data, time}
          localStorage.setItem('packages', JSON.stringify(PackageSizesDataTime))
          this.managePackageSize(response.data)
        }
      )
    },
    managePackageSize (packages) {
      this.packagesSize = packages
      this.packagesSize.forEach(packageSize => {
        this.packagesDic[packageSize.id] = packageSize.short_code
      })
      this.loadSupplies()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getPaymentStyle (data) {
      if (data.payment_requests.length > 0) return common.getOrderStatusColor(data.payment_requests[0].status)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getPaymentStatusLabel(order))
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getPaymentStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    loadSupplies () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}&status=${this.filters}`
      sendRequest(true, false, this, `${this.agentURl}${query}`, 'get', null, true,
        (response) => {
          this.supplies = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    cancelSupply (data) {
      this.supplyData = data
      this.$vs.dialog({
        color: 'danger',
        title: i18nData[this.$i18n.locale]['Confirm'],
        text: i18nData[this.$i18n.locale]['Are you sure you want to cancel this supply?'],
        acceptText: i18nData[this.$i18n.locale]['Yes'],
        accept: this.confirmCancelShipment
      })
    },
    confirmCancelShipment () {
      sendRequest(true, false, this, `api/v1/supplies/${this.supplyData.id}/`, 'patch', {status: 'cancelled'}, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Supply'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
          this.loadSupplies()
        }
      )
    },
    countItem (data) {
      let count = 0
      data.forEach(item => {
        count += item.count
      })
      return count
    }
  },
  created () {
    this.isAndroidFunction()
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    if (localStorage.getItem('packages') && (new Date() - new Date(JSON.parse(localStorage.getItem('packages')).time)) / (60 * 60 * 1000) <= 1) {
      this.managePackageSize(JSON.parse(localStorage.getItem('packages')).data)
    } else {
      this.loadPackagesSize()
    }
  }
}
</script>

<style lang='scss'>
.border-line {
  border-top: solid 2px #E4E4E4;
}
.supplies-table {
  td .content-tr-expand {
  display: block !important;
  padding: 10px 110px;
  }
  & table {
    border-collapse: separate;
    border-spacing: 0 10px;
    & td:not(:first-child) {
      text-align: center;
    }
  }
  & thead tr {
    background: rgba(236, 246, 255, 0.8);
    border-radius: 6px;
    th {
      padding: 15px 5px !important;
      font-weight: 500;
      font-size: 14px;
      color: #0A3266;
      div {
        display: block;
        text-align: center;
      }
    }
  }
  .vs-table--tr {
    font-weight: 500;
    font-size: 14px;
    & td:not(:first-child) {
      color: #000;
    }
    & td:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      border-left:  9px solid var(--statusColor);
    }
    & td:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    td {
      padding: 15px 5px !important;
    }
  }
  .tr-expand {
    .content-tr-expand {
      border: none;
    }
    td {
      padding: 0px !important;
      width: 100%;
      position: relative;
      top: -8px;
      background: #F1F7FC !important;
      box-shadow: inset 2px -2px 5px rgba(84, 84, 84, 0.1);
      border-radius: 6px;
    }
  }
}
.supply-order{
  .vs-collapse-item--header{
    background: white !important;
    padding: 0rem;
    user-select: none;
  }
}
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.disabled {
  color: #C5CBD0;
}
.supply-table-cell-content {
  background: rgba(28, 91, 254, 0.09);
  border-radius: 14.5px;
  padding: 5px 10px;
}
.status-icon {
  border-left: 4px solid;
  border-radius: 8px;
}
@mixin background-gradient {
  background-image: linear-gradient(45deg, #0A326630 0px, rgba(229, 229, 229, 0.8) 40px, #0A326630 80px);
}
.table-loader {
  th{
    background: rgba(220, 238, 255, 0.4);
    border-radius: 6px;
  }
  .line{
    @include background-gradient;
    animation: shine-lines 1s infinite alternate;
  }
}
@keyframes shine-lines {
  0% {background-position: 200px;}
  100% {background-position: -200px;}
}
</style>